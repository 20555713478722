import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CartLink = styled.a`
  text-decoration: none;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CartFilledDot = styled.span`
  position: absolute;
  top: -4px;
  right: -6px;
  width: 9px;
  height: 9px;
  text-align: center;
  font-size: 0.55rem;
  color: white;
  padding: 3px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.14) 0px 2px 4px 0px;
  background: rgb(57, 110, 148);
  border-radius: 50%;
`;
