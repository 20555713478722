import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.div`
  background: white;
  padding: 1.5em 1em;
  z-index: 20;
  position: relative;
  min-height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h4`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;

  a {
    font-size: 0.8em;
    font-weight: 400;
    color: ${props => props.theme.textColors['dark-400']};
    text-decoration: none;
  }
`;

export const LogoContainer = styled.a`
  width: auto;
  min-width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;

  svg {
    height: 25px;

    ${media.lg`
      padding: 4px;
      height: 30px;
    `}
  }
`;
