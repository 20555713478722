import styled, { css } from 'styled-components';

interface InputProps {
  hasError?: boolean;
  isFocused: boolean;
}

export const InputFieldStyles = (props: InputProps): any => css`
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 300;
  color: #1d2b3a;
  outline: none;
  padding: 0.8rem 0.65rem;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #cdd8e4;
  caret-color: black;

  ${props.isFocused && `border-color: #3fa1da;`}
  ${props.hasError && `border-color: #ea9ca4;`}

  ::placeholder {
    color: #ddd;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #ddd;
  }

  ::-ms-input-placeholder {
    color: #ddd;
    opacity: 0.3;
  }
`;

export const InputField = styled.input<InputProps>`
  ${InputFieldStyles}
`;

export const Label = styled.label`
  padding: 5px 0 10px;
  float: left;
  font-size: 1rem;
  font-weight: 700;
  background: transparent;
  color: ${(props: any) => props.theme.textColors['dark-500']};
`;

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 5px 0 0.6em 0.6em;
  font-size: 1em;
  font-weight: 400;
  color: #e64064;
`;

interface InputContainerProps {
  hasLabel?: boolean;
  fullWidth?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  background: transparent;
  border-radius: 4px;
  margin: 0.3em 0 0.5em;
  transition: all 250ms ease-in-out;
  ${props => props.hasLabel && `min-height: 60px;`}
  display: ${props => (props.fullWidth ? 'flex' : 'inline-flex')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  flex-direction: column;
  align-items: flex-start;
`;
