import React, { useState } from 'react';
import { v5 as uuid } from 'uuid';
import { useShop } from '@app/lib/shop';
import { isEmail } from '@app/lib/utils';
import { Input } from '@app/components/ui/Form/Input';
import { Button } from '@app/components/ui/Button';
import { Title, NewsletterDescription } from './Newsletter.styles';

interface NewsletterState {
  email: string;
  hasError: boolean;
  isSubmitting: boolean;
  success: boolean;
}

export const Newsletter: React.FC = () => {
  const shop = useShop();
  const [state, setState] = useState<NewsletterState>({
    email: '',
    hasError: false,
    isSubmitting: false,
    success: false,
  });

  const handleSubmit = async () => {
    setState({ ...state, hasError: false });

    const isValid = isEmail(state.email);

    if (!isValid) {
      setState({ ...state, hasError: true });
    } else {
      setState({ ...state, isSubmitting: true, hasError: false });

      (window as any)?.analytics?.identify(
        uuid(state.email, shop.uuid_email_namespace),
        { email: state.email?.trim() },
        {
          integrations: {
            Klaviyo: {
              listId: 'VGF5db',
              confirmOptin: false,
            },
          },
        }
      );

      setState({
        ...state,
        isSubmitting: false,
        success: true,
        email: '',
        hasError: false,
      });
    }
  };

  return (
    <>
      <Title>Keep In Touch</Title>

      <NewsletterDescription>
        Stay in touch with our latest inspiration, designs and more!
      </NewsletterDescription>

      <form onSubmit={e => e.preventDefault()}>
        <Input
          id="email"
          type="text"
          fullWidth={true}
          name="email"
          label="Email Address"
          placeholder="Email Address"
          value={state.email}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setState({ ...state, email: e.currentTarget.value })
          }
          hasError={state.hasError}
          errorMessage={'Please enter valid email.'}
        />

        <Button
          variant="primary"
          disabled={state.success}
          style={{
            width: '100%',
          }}
          type="submit"
          onClick={async () => await handleSubmit()}
        >
          {state.isSubmitting && 'Submitting...'}
          {!state.success && !state.isSubmitting && 'Subscribe'}
          {state.success && 'Subscribed!'}
        </Button>
      </form>
    </>
  );
};
