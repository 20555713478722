import { AppContext } from 'next/app';
import nookies from 'nookies';

const NEXT_PUBLIC_ANALYTICS_FUNNEL_COOKIE =
  process.env.NEXT_PUBLIC_ANALYTICS_FUNNEL_COOKIE;

export const getActiveFunnel = (
  ctx: AppContext['ctx'],
  splits: { control: string; variation: string }
): string => {
  const cookies = nookies.get(ctx);
  const userFunnel = cookies?.[NEXT_PUBLIC_ANALYTICS_FUNNEL_COOKIE];

  if (userFunnel === splits.control || userFunnel === splits.variation) {
    return userFunnel;
  }

  // 50/50 split
  const funnel = Math.random() < 0.5 ? splits.control : splits.variation;

  nookies.set(ctx, NEXT_PUBLIC_ANALYTICS_FUNNEL_COOKIE, funnel, {
    maxAge: 60 * 60 * 24 * 30, // 30 days,
    path: '/',
  });

  return funnel;
};
