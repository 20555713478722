import * as Sentry from '@sentry/browser';

const NODE_ENV = process.env.NODE_ENV;
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const isProd = NODE_ENV === 'production';

export const initSentry = (): void => {
  Sentry.init({
    debug: !isProd,
    environment: isProd ? 'production' : 'development',
    dsn: NEXT_PUBLIC_SENTRY_DSN,
  });
};
