import styled from 'styled-components';

interface SlideoutProps {
  isCart: boolean;
  hasGlobalBanner: boolean;
  size?: string;
}

const marginTop = (
  hasGlobalBanner: boolean,
  isCart: boolean,
  size: string
): number => {
  let margin = 60;

  if (isCart) {
    margin += 40;
  }

  if (size === 'lg') {
    margin += 80;
  } else if (size === 'md') {
    margin += 60;
  } else if (size === 'sm') {
    margin += 40;
  }

  return margin;
};

export const Slideout = styled.div<SlideoutProps>`
  background: white;
  width: 100%;
  height: calc(100vh - 3.5rem);
  max-height: calc(100vh - 3.5rem);
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 400ms ease-in-out;

  &.mobile-menu-enter {
    opacity: 0.01;
    transform: translateY(-100%);
    transition: all 400ms ease-in-out;
  }

  &.mobile-menu-exit {
    opacity: 1;
    transform: translateY(-100%);
  }

  &.mobile-menu-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: all 400ms ease-in-out;
  }

  &.mobile-menu-exit-done {
    opacity: 0;
    transform: translateY(-100%);
  }

  ${props => {
    return `
      &.mobile-menu-enter-active {
        opacity: 1;
        transform: translateY(calc(0% + ${marginTop(
          props.hasGlobalBanner,
          props.isCart,
          props.size
        )}px));
        transition: all 400ms ease-in-out;
      }
      
      &.mobile-menu-enter-done {
        opacity: 1;
        transform: translateY(calc(0% + ${marginTop(
          props.hasGlobalBanner,
          props.isCart,
          props.size
        )}px));
      }
    `;
  }}
`;

export const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    width: 100%;
    border-bottom: 1px solid #eee;

    :not(:last-child) {
      a {
        display: block;
        padding: 1.2rem;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.03rem;
        text-decoration: none;
        color: ${props => props.theme.textColors['dark-600']};
      }
    }

    &:last-child {
      text-align: center;
    }
  }
`;

export const FooterList = styled.ul`
  margin: 1.4rem;
  padding: 0;
  list-style: none;
  overflow: hidden;

  li {
    width: 100%;

    a {
      display: inline-block;
      padding: 0.5rem 0.4rem;
      font-size: 0.7rem;
      font-weight: 400;
      letter-spacing: 0.03rem;
      text-decoration: none;
      color: ${props => props.theme.textColors['dark-600']};
    }
  }
`;
