import { useEffect, useState } from 'react';
import { AppProps, AppContext } from 'next/app';
import Router from 'next/router';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Analytics from '@andalways/analytics-js';
import { getAnonymousId } from '@app/lib/analytics';
import { initSentry } from '@app/lib/sentry';
import { ScreenClassProvider } from 'react-grid-system';
import { ThemeProvider } from 'styled-components';
import { CartProvider } from '@app/lib/cart';
import { ShopProvider } from '@app/lib/shop';
import { GraphqlProvider } from '@app/lib/graphql';
import { FunnelProvider } from '@app/contexts/funnel';
import { AppStateProvider } from '@app/contexts/app';
import { SplitProvider } from '@app/contexts/split-io';
import { DocumentHead } from '@app/components/DocumentHead';
import { getActiveFunnel } from '@app/lib/utils/getActiveFunnel';
import { getLayout } from '@app/layouts';
import { GlobalStyles } from '@app/styles';
import { getTheme } from '@app/styles/themes';
import '../styles/tailwind.css';

// process destructuring now throws error
const NEXT_PUBLIC_ANALYTICS_PROPERTY =
  process.env.NEXT_PUBLIC_ANALYTICS_PROPERTY;
const NEXT_PUBLIC_ANALYTICS_URI = process.env.NEXT_PUBLIC_ANALYTICS_URI;
const NEXT_PUBLIC_PAYPAL_KEY = process.env.NEXT_PUBLIC_PAYPAL_KEY;
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Router.events.on('routeChangeStart', () => window?.scrollTo(0, 0));
Router.events.on('routeChangeComplete', url => {
  const segment = (window as any)?.analytics;

  segment.page(url);
  Analytics.pageview();
});

if (NEXT_PUBLIC_SENTRY_DSN) {
  initSentry();
}

if (NEXT_PUBLIC_ANALYTICS_URI && NEXT_PUBLIC_ANALYTICS_PROPERTY) {
  Analytics.init({
    api: NEXT_PUBLIC_ANALYTICS_URI,
    property: NEXT_PUBLIC_ANALYTICS_PROPERTY,
    pageviewOnInit: false,
    anonymousId: getAnonymousId(),
  });
}

function App({ Component, pageProps }: AppProps): JSX.Element {
  const { funnel, theme } = pageProps;
  const paypalOptions = {
    'client-id': NEXT_PUBLIC_PAYPAL_KEY,
    components: 'messages',
  };

  const Layout = getLayout();
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    setShowComponent(true);

    Analytics.setFunnel(funnel);
    Analytics.pageview();
  }, []);

  return (
    <PayPalScriptProvider deferLoading={true} options={paypalOptions}>
      <ShopProvider>
        <FunnelProvider funnel={funnel}>
          <AppStateProvider>
            <GraphqlProvider>
              <SplitProvider>
                <CartProvider>
                  <ThemeProvider theme={getTheme(theme) as any}>
                    <ScreenClassProvider fallbackScreenClass={'xs'}>
                      <Layout>
                        <GlobalStyles />
                        <DocumentHead />

                        {showComponent ? (
                          <Component {...pageProps} />
                        ) : (
                          <div className="h-screen" />
                        )}
                      </Layout>
                    </ScreenClassProvider>
                  </ThemeProvider>
                </CartProvider>
              </SplitProvider>
            </GraphqlProvider>
          </AppStateProvider>
        </FunnelProvider>
      </ShopProvider>
    </PayPalScriptProvider>
  );
}

const splits = {
  control: 'redesign-v2',
  variation: 'redesign-v2',
};

// TODO (skeen): clean funnel logic into library
App.getInitialProps = ({ ctx }: AppContext) => {
  const funnel = getActiveFunnel(ctx, splits);
  const theme = funnel === 'redesign-v2' ? 'redesign' : 'base';

  return { pageProps: { theme, funnel } };
};

export default App;
