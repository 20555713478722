import React from 'react';
import Link from 'next/link';
import * as Grid from 'react-grid-system';
import { Menu, Title } from './Navigation.styles';

interface LinkType {
  href: string;
  name: string;
  target?: string;
}

const links: LinkType[] = [
  { name: 'Create Map', href: '/create' },
  { name: 'Inspiration', href: '/inspiration' },
  { name: 'Blog', href: '/blog' },
  { name: 'About', href: '/about' },
  { name: 'Reviews', href: '/reviews' },
];

const companyLinks: LinkType[] = [
  // {
  //   name: 'Become an Affiliate',
  //   href: 'https://www.shareasale.com/join/98359',
  //   target: '_blank',
  // },
  { name: 'FAQ', href: '/faq' },
  { name: 'Support', href: '/support' },
  { name: 'Terms & Conditions', href: '/terms' },
  { name: 'Privacy Policy', href: '/privacy' },
];

export const Navigation: React.FC = () => (
  <>
    <Grid.Col sm={12} lg={2}>
      <Title>Navigation</Title>

      <Menu>
        {links.map(link => {
          if (!link.href) return null;

          return (
            <li key={`nav-footer-list-${link.name}`}>
              <Link href={link.href} passHref prefetch={false}>
                <a>{link.name}</a>
              </Link>
            </li>
          );
        })}
      </Menu>
    </Grid.Col>

    <Grid.Col sm={12} lg={3}>
      <Title>Company</Title>

      <Menu>
        {companyLinks.map(link => (
          <li key={`nav-footer-list-${link.name}`}>
            {!link.target ? (
              <Link href={link.href} passHref prefetch={false}>
                <a>{link.name}</a>
              </Link>
            ) : (
              <a href={link.href} target={link.target}>
                {link.name}
              </a>
            )}
          </li>
        ))}
      </Menu>
    </Grid.Col>
  </>
);
