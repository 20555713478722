import styled from 'styled-components';

interface ColProps {
  right?: boolean;
}

export const Col = styled.div<ColProps>`
  flex: 1;
  width: 100%;
  margin: 0 1rem;
  text-align: ${props => (props.right ? 'right' : 'left')};
`;

interface FrameTextProps {
  right?: boolean;
}

export const FrameText = styled.div<FrameTextProps>`
  margin: 6px 0;
  padding: 0;
  opacity: 0.7;
  font-size: 0.9rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  padding: 1rem 1rem 0;

  ${Col} {
    &:last-child {
      flex: 0;
    }
  }
`;

export const Container = styled.div`
  padding: 1rem 0;
`;

export const RetailPrice = styled.span`
  margin-right: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(219, 66, 60);
  text-decoration: line-through;
`;
