import { useRouter } from 'next/router';
import { ShopLayout } from '@app/layouts/shop';
import { CheckoutLayout } from '@app/layouts/checkout';

const CHECKOUT_LAYOUT_PATHS = [
  '/checkout',
  '/confirmation',
  '/order',
  '/shipment',
];

export const getLayout = (): React.ElementType => {
  const router = useRouter();

  let Layout = ShopLayout;

  if (CHECKOUT_LAYOUT_PATHS.some(page => router.pathname.indexOf(page) > -1)) {
    Layout = CheckoutLayout;
  }

  return Layout;
};
