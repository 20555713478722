import * as React from 'react';
import { useRouter } from 'next/router';
import * as Grid from 'react-grid-system';
import { useShop } from '@app/lib/shop';
import { Navigation, Newsletter, SocialIcons } from './components';
import {
  Container,
  Copyright,
  MadeWithLove,
  MadeWithLoveIcon,
} from './Footer.styles';

export const Footer: React.FC = () => {
  const shop = useShop();
  const router = useRouter();
  const path = router.pathname.slice(0, 7);

  if (path === '/create' || path === '/frames') return null;

  return (
    <Container>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col md={12} lg={3}>
            <SocialIcons />
          </Grid.Col>
          <Navigation />
          <Grid.Col sm={12} lg={4}>
            <Newsletter />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} md={12}>
            <MadeWithLove>
              Made with{' '}
              <MadeWithLoveIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  width="30px"
                  height="30px"
                >
                  <path
                    fill="#f78f8f"
                    d="M20.023,35.377C17.729,33.522,2.5,20.933,2.5,13.739C2.5,8.645,6.624,4.5,11.692,4.5 c3.251,0,6.197,1.682,7.878,4.498L20,9.717l0.429-0.719C22.111,6.182,25.056,4.5,28.308,4.5c5.069,0,9.192,4.145,9.192,9.239 C37.5,22.858,22.364,33.749,20.023,35.377z"
                  />
                  <path
                    fill="#c74343"
                    d="M28.308,5C33.101,5,37,8.92,37,13.739c0,8.548-13.881,18.839-16.958,21.015 C14.791,30.513,3,19.734,3,13.739C3,8.92,6.899,5,11.692,5c3.074,0,5.858,1.59,7.449,4.254L20,10.692l0.859-1.438 C22.449,6.59,25.234,5,28.308,5 M28.308,4C24.776,4,21.694,5.905,20,8.742C18.306,5.905,15.224,4,11.692,4C6.339,4,2,8.36,2,13.739 C2,21.814,20,36,20,36s18-12.043,18-22.261C38,8.36,33.661,4,28.308,4L28.308,4z"
                  />
                </svg>
              </MadeWithLoveIcon>{' '}
              in San Diego, California
            </MadeWithLove>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} md={12}>
            <Copyright>
              Copyright &copy; {new Date().getFullYear()} {shop.title}.
            </Copyright>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
};
