import * as React from 'react';
import { List, Title } from './SocialIcons.styles';

interface Socials {
  name: string;
  icon: any;
  href: string;
}

const socials: Socials[] = [
  {
    name: 'Facebook',
    icon: require('./images/Facebook.svg'),
    href: 'https://www.facebook.com/overourmoon/',
  },
  {
    name: 'Pinterest',
    icon: require('./images/Pinterest.svg'),
    href: 'https://www.pinterest.com/overourmoon',
  },
  {
    name: 'Instagram',
    icon: require('./images/Instagram.svg'),
    href: 'https://www.instagram.com/overourmoon/',
  },
];

export const SocialIcons: React.FC = () => (
  <>
    <Title>Social Media</Title>

    <List>
      {socials.map(social => (
        <li key={`social-list-${social.name}`}>
          <a href={social.href} target="_blank" rel="noreferrer">
            <img src={social.icon} alt={social.name} />
          </a>
        </li>
      ))}
    </List>
  </>
);
