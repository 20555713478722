import styled from 'styled-components';
import { media } from '@app/styles';

export const RightMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 1.5rem;
`;

interface MenuIconProps {
  isActive: boolean;
}

export const MenuIcon = styled.div<MenuIconProps>`
  background-color: ${props => props.theme.bgColors['light-200']};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 100%;
  cursor: pointer;

  > svg {
    width: ${props => (props.isActive ? 16 : 20)}px;
    height: ${props => (props.isActive ? 16 : 20)}px;
  }

  ${media.xl`
    display: none;
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding-left: 1rem;

  svg {
    height: 56px;
    max-width: 132px;

    ${media.md`
      max-width: 160px;
    `}
  }

  a {
    min-width: 130px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

interface NavBarProps {
  isAlt?: boolean;
}

export const Container = styled.div<NavBarProps>`
  ${props => props.theme.nav.background}
  box-shadow: rgba(43, 68, 107, 0.1) 0px 0px 0.625rem 0px;
  position: fixed;
  // position: absolute;
  top: 0;
  // left: 0;
  z-index: 20;
  width: 100%;
`;

export const Inner = styled.div<NavBarProps>`
  ${props => props.theme.nav.background}
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;

  ${media.lg`
    padding: 0.7rem 1.3rem 0.85rem;
  `};
`;
