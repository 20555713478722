import styled from 'styled-components';

export const CouponCode = styled.span`
  font-weight: bold;
`;

export const CouponCodeRemoveIcon = styled.span`
  padding: 0 8px;
  cursor: pointer;

  img {
    opacity: 0.3;
    margin-left: 10px;
  }
`;
