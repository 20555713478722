import * as React from 'react';
import Link from 'next/link';
import { Logo } from '@app/components/Logo';
import { Container, LogoContainer, Title } from './Header.styles';

export const Header: React.FC = () => (
  <Container>
    <Link href="/" passHref>
      <LogoContainer>
        <Logo />
      </LogoContainer>
    </Link>

    <Title>
      <Link href="/" passHref>
        <a>Back to Shop</a>
      </Link>
    </Title>
  </Container>
);
