import * as React from 'react';
import { useCart } from '@app/lib/cart';
import { formatCurrency } from '@app/lib/utils';
import { Loader } from '@app/components/ui/Loader';
import { Coupon, OrderTableItems } from './components';
import { Coupon as CouponComponent } from '@app/views/checkout/components/CheckoutForm/PaymentForm/components/Coupon/index';
import {
  Container,
  Col,
  CurrencyCode,
  Pricing,
  Row,
  SmallText,
} from './OrderTable.styles';

export const OrderTable: React.FC = () => {
  const { cart, loading } = useCart();

  if (loading) return <Loader fullWidth={true} />;
  if (!cart?.id || cart?.store !== cart?.shopConfig?.store) return null;

  return (
    <Container>
      {cart.isEmpty && <h3>No items in cart.</h3>}

      {!cart.isEmpty && (
        <OrderTableItems items={cart.items} itemsTotal={cart.itemsTotal} />
      )}

      {!cart.isPaid && (
        <div
          style={{
            margin: '0 16px',
            paddingTop: '1.3em',
            borderTop: '1px solid #eee',
          }}
        >
          <CouponComponent />
        </div>
      )}

      <Pricing>
        <Row>
          <Col>Subtotal</Col>
          <Col right> {cart.formattedSubtotal} </Col>
        </Row>

        {cart.discount && <Coupon cart={cart} />}

        <Row>
          <Col>Shipping</Col>
          <Col right>
            {' '}
            {cart.shipping ? (
              formatCurrency(cart.shipping?.amount)
            ) : (
              <SmallText>Calculated at next step</SmallText>
            )}{' '}
          </Col>
        </Row>

        {cart.tax && (
          <Row>
            <Col>Tax</Col>
            <Col right>{cart.formattedTaxAmount}</Col>
          </Row>
        )}

        <Row style={{ color: '#76b13d', fontSize: '0.9em' }}>
          <Col>You Save</Col>
          <Col right>{cart.formattedSavings}</Col>
        </Row>

        <Row>
          <Col>Total</Col>
          <Col right>
            {cart.formattedTotal} <CurrencyCode>USD</CurrencyCode>
          </Col>
        </Row>
      </Pricing>
    </Container>
  );
};
