import * as React from 'react';
import { Container, Text } from './GlobalBanner.styles';
import config from '@app/config';

const style = config.globalBanner;

export const GlobalBanner: React.FC = () => (
  <>
    {config.globalBanner && (
      <Container size={style.size} variant={style.variant}>
        <Text size={style.size} bold={style.bold}>
          {style.message}
        </Text>
      </Container>
    )}
  </>
);
