import fetch from 'isomorphic-unfetch';
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { responseToModel } from './utils/responseToModel';

const { NEXT_PUBLIC_GRAPH_URI } = process.env;
const isServer = typeof window === 'undefined';

const client = new ApolloClient({
  cache: new InMemoryCache().restore({}),
  connectToDevTools: isServer,
  link: ApolloLink.from([
    responseToModel,
    new RetryLink(),
    new HttpLink({ uri: NEXT_PUBLIC_GRAPH_URI, fetch }),
  ]),
  ssrMode: false,
});

export const GraphqlProvider: React.FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
