import React, { memo } from 'react';
import * as Grid from 'react-grid-system';
import Sticky from 'react-stickynode';
import { WhyChooseUs } from '@app/components/WhyChooseUs';
import { GlobalBanner } from '@app/components/GlobalBanner';
import { Header, Footer, OrderTable, MobileOrderSummary } from './components';
import { Container, Content, Body, Sidebar } from './CheckoutLayout.styles';

export const CheckoutLayout: React.FC = memo(({ children }) => (
  <Container>
    <Content>
      <GlobalBanner />

      <Body id="checkout-sticky-boundary">
        <Header />
        <MobileOrderSummary />

        {children}
      </Body>

      <Footer />
    </Content>

    <Sidebar>
      <Grid.Col>
        <Sticky innerZ={10} top={0} bottomBoundary="#checkout-sticky-boundary">
          <OrderTable />
          <WhyChooseUs />
        </Sticky>
      </Grid.Col>
    </Sidebar>
  </Container>
));
