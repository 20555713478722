import React from 'react';
import Image from 'next/image';
import * as Analytics from '@app/lib/analytics';
import { Cart } from '@app/lib/graphql/models';
import { formatCurrency } from '@app/lib/utils';
import { useRemoveVoucherMutation } from '@app/lib/graphql/schema';
import { CouponCode, CouponCodeRemoveIcon } from './Coupon.styles';
import { Row, Col } from '../../OrderTable.styles';

interface CouponProps {
  cart: Cart;
}

export const Coupon: React.FC<CouponProps> = ({ cart }) => {
  const [removeVoucher, { loading }] = useRemoveVoucherMutation();
  const code = cart.couponCode;

  const onRemoveClick = async () => {
    try {
      await removeVoucher({
        variables: { input: { id: cart.id, code } },
      });

      Analytics.track('couponRemoved', cart, code);
    } catch (e) {
      // noop
    }
  };

  return (
    <Row style={{ opacity: loading ? 0.2 : 1 }}>
      <Col>
        Discount (<CouponCode>{code}</CouponCode>)
        {!cart.paidAt && (
          <CouponCodeRemoveIcon onClick={onRemoveClick}>
            <Image
              width={10}
              height={10}
              alt="Close"
              src="/assets/icons/close.png"
            />
          </CouponCodeRemoveIcon>
        )}
      </Col>

      <Col right>
        {`-${formatCurrency(Math.abs(cart?.discount?.amount) ?? 0)}`}
      </Col>
    </Row>
  );
};
