import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CSSTransition } from 'react-transition-group';
import { links } from '../../links';
import { LinkButton } from '@app/components/ui/Button';
import { FooterList, NavList, Slideout } from './MobileMenu.styles';
import config from '@app/config';

const hasGlobalBanner = Boolean(config.globalBanner);

interface MobileMenuProps {
  isActive: boolean;
  setActive?(e?: any): any;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  isActive,
  setActive = () => {},
}) => {
  const router = useRouter();

  return (
    <CSSTransition in={isActive} timeout={300} classNames="mobile-menu">
      <Slideout
        isCart={router.pathname.indexOf('/cart') > -1}
        hasGlobalBanner={hasGlobalBanner}
        size={hasGlobalBanner ? config.globalBanner.size : null}
      >
        <NavList>
          {links.map(link => (
            <li key={link.name}>
              <Link href={link.href} passHref prefetch={false}>
                <a onClick={() => setActive()}>{link.name}</a>
              </Link>
            </li>
          ))}
          <li>
            <Link href="/create" passHref>
              <LinkButton
                variant="primary"
                style={{ margin: '1.4rem auto', textAlign: 'center' }}
                onClick={() => setActive()}
              >
                Create Your Moon Map
              </LinkButton>
            </Link>
          </li>
        </NavList>

        <FooterList>
          <li>
            <Link href="/terms">
              <a onClick={() => setActive()}>Terms & Conditions</a>
            </Link>
          </li>
          <li>
            <Link href="/privacy">
              <a onClick={() => setActive()}>Privacy Policy</a>
            </Link>
          </li>
        </FooterList>
      </Slideout>
    </CSSTransition>
  );
};
