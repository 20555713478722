import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-top: 1px solid #dfedf7;
`;

export const Inner = styled.div`
  text-align: center;
  padding-bottom: 1.5rem;
`;
