import React, { useState } from 'react';
import Image from 'next/image';
import { useCart } from '@app/lib/cart';
import { formatCurrency } from '@app/lib/utils';
import { Coupon as CouponComponent } from '@app/views/checkout/components/CheckoutForm/PaymentForm/components/Coupon/index';
import {
  Container,
  CurrencyCode,
  Header,
  Row,
  RowName,
  RowNameSmall,
  RowPrice,
  Table,
  Title,
  TitlePrice,
  RetailPrice,
  SmallText,
} from './MobileOrderSummary.styles';

export const MobileOrderSummary: React.FC = () => {
  const { cart } = useCart();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!cart?.id) return null;

  return (
    <Container>
      <Header onClick={() => setIsExpanded(!isExpanded)}>
        <Image
          width={18}
          height={16}
          alt="Shopping Cart"
          src="/assets/icons/shopping-cart.svg"
        />

        <Title>{isExpanded ? 'Hide' : 'Show'} Order Summary</Title>
        <TitlePrice>
          {cart.formattedTotal}{' '}
          <CurrencyCode marginRight={false}>USD</CurrencyCode>
        </TitlePrice>
      </Header>

      {isExpanded && (
        <Table>
          {cart.items.map(item => (
            <Row key={item.id}>
              <RowName>
                {item.displayName}
                <RowNameSmall>{item.description}</RowNameSmall>
                {!item.isAddOn && (
                  <RowNameSmall>
                    Size: {item.printMetadata?.width} ×{' '}
                    {item.printMetadata?.height} inches
                  </RowNameSmall>
                )}
                {!item.isDigitalPrint && !item.isRushOrder && (
                  <RowNameSmall>Quantity: {item.quantity}</RowNameSmall>
                )}
              </RowName>
              <RetailPrice>
                {!item.isAddOn &&
                  formatCurrency(item.retailPrice * item.quantity)}
              </RetailPrice>
              <RowPrice>{item.formattedTotal}</RowPrice>
            </Row>
          ))}

          {!cart.isPaid && (
            <div style={{ paddingTop: '1.3em' }}>
              <CouponComponent />
            </div>
          )}

          <Row borderTop={true}>
            <RowName>Subtotal</RowName>
            <RowPrice>{cart.formattedSubtotal}</RowPrice>
          </Row>

          {cart?.voucher && (
            <Row color="#36a018">
              <RowName>Coupon ({cart.couponCode})</RowName>
              <RowPrice>{`-${formatCurrency(
                Math.abs(cart?.voucher?.amount || 0)
              )}`}</RowPrice>
            </Row>
          )}

          <Row>
            <RowName>Shipping</RowName>
            <RowPrice>
              {cart.shipping ? (
                formatCurrency(cart.shipping?.amount)
              ) : (
                <SmallText>Calculated at next step</SmallText>
              )}
            </RowPrice>
          </Row>

          {cart.tax && (
            <Row>
              <RowName>Tax</RowName>
              <RowPrice>{cart.formattedTaxAmount}</RowPrice>
            </Row>
          )}

          <Row style={{ color: '#76b13d', fontSize: '0.9em' }}>
            <RowName>You Save</RowName>
            <RowPrice>{cart.formattedSavings}</RowPrice>
          </Row>

          <Row bold>
            <RowName>Total</RowName>
            <RowPrice>
              {cart.formattedTotal}{' '}
              <CurrencyCode marginRight={true}>USD</CurrencyCode>
            </RowPrice>
          </Row>
        </Table>
      )}
    </Container>
  );
};
