import styled from 'styled-components';
import { media } from '@app/styles';

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 15px;
  font-size: 0.9rem;
`;

interface CurrencyCodeProps {
  marginRight: boolean;
}

export const CurrencyCode = styled.span<CurrencyCodeProps>`
  font-size: 0.8rem;
  font-weight: 400;
  ${props => props.marginRight && `margin-right: 8px;`}
`;

export const Row = styled.div<{
  bold?: boolean;
  color?: string;
  borderTop?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 12px 0;
  font-size: 0.9rem;
  font-weight: ${props => (props.bold ? '600' : '400')};
  color: ${props => (props.color ? props.color : 'inherit')};

  ${props =>
    props.borderTop &&
    `
    margin-top: 10px;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  `}
`;

export const RowName = styled.div`
  flex: 1;
  padding-left: 16px;
`;

export const RowNameSmall = styled.div`
  margin-top: 5px;
  font-size: 0.75rem;
  color: #777777;
`;

export const RowPrice = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
`;

export const SmallText = styled.span`
  font-weight: 400;
  font-size: 0.74rem;
  margin-right: 4px;
  opacity: 0.9;
`;

export const Title = styled.div`
  position: relative;
  display: block;
  flex: 1;
  padding-left: 18px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #2b67b3;
`;

export const TitlePrice = styled.div`
  flex: 1;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 0 1em;
  background: #f9fafb;
  border-top: 1px solid #ebeff3;
  border-bottom: 1px solid #ebeff3;

  ${media.lg`
    display: none;
  `}
`;

export const RetailPrice = styled.span`
  margin-right: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(219, 66, 60);
  text-decoration: line-through;
`;
