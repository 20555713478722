import styled from 'styled-components';
import { media } from '@app/styles';

export const NewsletterDescription = styled.p`
  margin: 0 0 10px;
  padding: 0;
  font-size: 0.9em;
  color: #4e5d71;
  line-height: 1.5;
`;

export const Title = styled.h4`
  ${(props: any) => props.theme.fonts.secondary}
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1em;

  ${media.lg`
    margin-bottom: 2em;
  `};
`;
