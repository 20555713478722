export default {
  colors: {
    'sky-blue': '#DCF2FF',
    'gold-star': '#FFBF00',
    'moon-sand': '#FFF9F4',
    'deep-blue': '#2E71CE',
    'night-sky': '#003D70',
  },
  body: `
      background: #ffffff;
      font-family: 'IBM Plex Sans', -apple-system, 'Segoe UI', sans-serif;
      font-size: 16px;
      color: #000000;
      overflow: scroll;
    `,
  a: `
      color: #338d95;
    `,
  fonts: {
    headline_1: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 4.25rem;
      line-height: 1.1;
    `,
    headline_2: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 3.5rem;
      line-height: 1.1;
  `,
    headline_3: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 2.875rem;
      line-height: 1.1;
  `,
    headline_4: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.1;
  `,
    body: `
      font-family: 'IBM Plex Sans', sans serif;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6;
  `,
    testimonials: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.6;
  `,
    subhead: `
      font-family: 'IBM Plex Sans', sans serif;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.1;
  `,
    button: `
      font-family: 'IBM Plex Sans', sans serif;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.6;
  `,
    microcopy: `
      font-family: 'IBM Plex Sans', sans serif;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 1.1;
      text-transform: uppercase;
  `,
    review_name: `
      font-family: 'DM Serif Display', serif;
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 1.1;
  `,
    review_verified: `
      font-family: 'IBM Plex Sans', sans serif;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.6;
  `,
    // legacy
    secondary: `
        font-family: 'DM Serif Display', serif;
      `,
  },
  buttons: {
    common: `
      color: #000;
      display: inline-flex;
      border-radius: 3px;
      text-decoration: none;
      border-style: none;
      border-width: initial;
      border-color: initial;
      border-image: initial;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      letter-spacing: 0.03rem;
    `,
    default: `
      color: #113450;
      background: linear-gradient(to top, #f6f7f9, #ffffff);
      border: 1px solid #efefef;
      font-size: 0.88rem;
      border-radius: 50px;
      `,
    primary: `
      background: #FFBF00;
      color: #003D70;
      font-size: 1.1rem;
      border-radius: 50px;
      `,
    secondary: `
      background: #2f4760;
      color: white;
      border-radius: 50px;
      width: 100%;
      `,
    text: `
        background: transparent;
        font-size: 0.88rem;
      `,
  },
  alerts: {
    default: `
        background: #f2fbfc;
        border: 1px solid #c6e3e5;
        color: #2b8d96;
      `,
  },
  nav: {
    background: `
        background-color: #DCF2FF;
      `,
  },
  // legacy (needed for non-landing pages)
  bgColors: {
    'light-100': '#fffaf5',
    'light-200': '#f9fdfd',
    'gray-100': '#F5FAFB',
    'light-blue-100': '#EEF8FF',
    'light-blue-200': '#DCF2FF',
  },
  textColors: {
    'dark-200': '#8090a4',
    'dark-300': '#647c9e',
    'dark-400': '#465c80',
    'dark-500': '#113450',
    'dark-600': '#083533',
    'dark-700': '#000000',
    'light-100': '#b8c2cc',
    'light-200': '#aaaaaa',
    'light-300': '#8795a1',
    'light-400': '#647c9e',
    'light-500': '#113450',
    'light-700': '#3a4f64',
  },
};
