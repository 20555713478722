import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.div`
  position: fixed;
  bottom: -10rem;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fff1f1;
  border-bottom: 1px solid #ffd7d7;
  color: #d30d0d;
  z-index: 100;
  transform: translateY(-10rem);
  animation: translateY 0.75s ease-in-out;
  @keyframes translateY {
    0% {
      transform: translateY(0rem);
    }
    100% {
      transform: translateY(-10rem);
    }
  }

  ${media.lg`
    justify-content: center;
    align-items: center;
    height: 70px;
  `}
`;

export const Copy = styled.p`
  position: relative;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0.7rem 3.5rem 0.7rem 0.7rem;
  line-height: 1.5em;
  text-align: left;

  ${media.lg`
    padding: 0 3.5rem;
    text-align: center;
  `}
`;

export const Link = styled.a`
  color: #d30d0d;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
`;
