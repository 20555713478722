import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 40px;
  background: white;
  border-top: 1px solid #dfedf7;

  ${media.lg`
    overflow-x: hidden;
  `}
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
  height: 40px;
  max-height: 40px;
  font-size: 0.8rem;
  font-weight: 300;
  color: ${props => props.theme.textColors['dark-500']};

  ${media.lg`
    margin: 0 auto;
    overflow-x: hidden;
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.035rem;
  padding: 0.7em 2.5em;

  svg,
  img {
    display: inline-block;
    margin-right: 12px;
    width: 18px;
    height: 18px;
    opacity: 0.75;
  }
`;
