import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useShop } from '@app/lib/shop';
import * as Analytics from '@app/lib/analytics';

const { NEXT_PUBLIC_SEGMENT_KEY } = process.env;
const IS_SERVER = typeof window === 'undefined';

const PRECONNECT_ORIGINS: string[] = [
  'cx.atdmt.com',
  'api.reviews.io',
  'ajax.googleapis.com',
  'cdn.taboola.com',
  'settings.luckyorange.net',
  'ssl.luckyorange.com',
  'api.getdrip.com',
  'tag.getdrip.com',
  'www.facebook.com',
  'ct.pinterest.com',
  's.pinimg.com',
  'connect.facebook.net',
  'bat.bing.com',
  'api.overourmoon.com',
  'a.overourmoon.com',
  'cdn.overourmoon.com',
  'fonts.googleapis.com',
  'cdn.polyfill.io',
  'widget.reviews.io',
  'www.google.com',
  'www.google.ca',
  'maps.googleapis.com',
  'www.googleadservices.com',
  'googleads.g.doubleclick.net',
  'stats.g.doubleclick.net',
  'www.googletagmanager.com',
  'www.google-analytics.com',
  'amplifypixel.outbrain.com',
  'tr.outbrain.com',
];

export const DocumentHead: React.FC = () => {
  const shop = useShop();
  const router = useRouter();

  const isCheckoutPage = router.pathname === '/checkout';

  return (
    <Head>
      {PRECONNECT_ORIGINS.map(origin => (
        <link key={origin} rel="preconnect" href={`//${origin}`} />
      ))}
      <link
        rel="icon"
        type="image/x-icon"
        href={require('./images/favicon.png')}
      />
      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&family=DM+Serif+Display:ital@0;1&family=Montserrat:wght@300;400&display=swap"
        rel="stylesheet"
      />
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta name="description" content={shop.seo.description} />
      <meta name="p:domain_verify" content={shop.seo['p:domain_verify']} />
      <meta property="og:locale" content={shop.seo['og:locale']} />
      <meta property="og:shop_name" content={shop.title} />
      <meta
        property="og:image"
        content={`https://${shop.domain}${shop.seo['og:image']}`}
      />
      <meta property="og:type" content={shop.seo['og:type']} />
      <meta property="og:description" content={shop.seo['og:description']} />

      {isCheckoutPage && <script src="https://pay.google.com/gp/p/js/pay.js" />}

      {!IS_SERVER && (
        <script
          async
          key="segment-tag"
          dangerouslySetInnerHTML={{
            __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0"; analytics.load("${NEXT_PUBLIC_SEGMENT_KEY}"); analytics.page();analytics.setAnonymousId("${Analytics.getAnonymousId()}");}}();`,
          }}
        />
      )}
    </Head>
  );
};
