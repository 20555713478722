import styled from 'styled-components';
import { media } from '@app/styles';

interface ContainerProps {
  variant: string;
  size: string;
}

interface TextProps {
  size: string;
  bold: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${props =>
    props.size === 'lg' ? '80px' : props.size === 'md' ? '60px' : '40px'};
  padding: 0.3rem 0.6rem;
  background: ${props =>
    props.variant === 'info'
      ? '#D0DFE8'
      : props.variant === 'warning'
      ? '#FFFFC1'
      : '#fff1f1'};
  border-bottom: 1px solid
    ${props =>
      props.variant === 'info'
        ? '#85c1e8'
        : props.variant === 'warning'
        ? '#CECE00'
        : '#ffd7d7'};
  color: ${props =>
    props.variant === 'info'
      ? '#1E608B'
      : props.variant === 'warning'
      ? '#585858'
      : '#d30d0d'};
`;

export const Text = styled.h2<TextProps>`
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: ${props =>
    props.size === 'lg' ? '1rem' : props.size === 'md' ? '0.8rem' : '0.7rem'};
  font-weight: ${props => (props.bold === 'true' ? 700 : 500)};
  text-align: center;
  line-height: 1.5;

  ${media.md`
    font-size: 0.85rem;
  `}
`;
