import React, { memo, useState } from 'react';
import {
  InputContainer,
  InputField,
  ErrorMessage,
  Label,
} from './Input.styles';

export interface InputProps {
  autoComplete?: 'off' | 'on' | 'new-password';
  autoCorrect?: string;
  autoCapitalize?: string;
  containerClass?: string;
  disabled?: boolean;
  errorMessage?: string | null;
  fullWidth?: boolean;
  hasError?: boolean;
  id?: string;
  label?: string | null;
  name?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onInput?: () => void;
  onKeyPress?: (e: any) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onClick?: (e: any) => void;
  placeholder?: string;
  ref?: React.Ref<any>;
  spellCheck?: boolean;
  type?: string;
  value?: string;
}

export const Input: React.FC<InputProps> = memo(
  ({
    autoCapitalize = 'none',
    autoComplete = 'on',
    autoCorrect = 'off',
    containerClass = '',
    disabled = false,
    errorMessage = null,
    fullWidth = false,
    hasError = false,
    id = '',
    label = null,
    name = '',
    onChange = () => {},
    onInput = () => {},
    onKeyPress = () => {},
    onBlur = () => {},
    onClick = () => {},
    placeholder = '',
    spellCheck = false,
    type = 'text',
    value = '',
  }) => {
    const inputRef = React.createRef<HTMLInputElement>();
    const [isFocused, setFocus] = useState<boolean>(false);

    return (
      <>
        <InputContainer
          onClick={() =>
            inputRef && inputRef.current && inputRef.current.focus()
          }
          hasLabel={!!label}
          className={containerClass}
          fullWidth={fullWidth}
        >
          {label && <Label htmlFor={name}>{label}</Label>}

          <InputField
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            disabled={disabled}
            isFocused={isFocused}
            hasError={hasError}
            id={id}
            name={name}
            onChange={onChange}
            onInput={onInput}
            onBlur={e => {
              onBlur(e);
              setFocus(false);
            }}
            onClick={onClick}
            onFocus={() => setFocus(true)}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            ref={inputRef}
            spellCheck={spellCheck}
            type={type}
            value={value}
          />
        </InputContainer>

        {hasError && errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </>
    );
  }
);
