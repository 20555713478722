import styled from 'styled-components';
import { media } from '@app/styles';

export const List = styled.ul`
  margin: 0.4em 0 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: inline-block;

  li {
    margin: 0 1.5em 0 0;
    padding: 0;
    float: left;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      cursor: pointer;
      text-decoration: none;

      &:hover img {
        transition: all 200ms ease-in-out;
        opacity: 1;
      }

      img {
        transition: all 200ms ease-in-out;
        opacity: 0.7;
        width: 40px;
      }
    }
  }
`;

export const Title = styled.h4`
  ${(props: any) => props.theme.fonts.secondary}
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1em;

  ${media.lg`
    margin-bottom: 2em;
  `};
`;
