import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg`
    flex-direction: row;
  `}
`;

export const Body = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 100%;

  ${media.lg`
    max-width: 70%;
  `}
`;

export const Content = styled.div`
  flex: 1;

  ${Body} {
    padding-top: 0;
  }
`;

export const Sidebar = styled.div`
  width: 420px;
  min-height: 100vh;
  display: none;
  flex-basis: 420px;
  z-index: 30;
  background: #f9fafb;
  border-left: 1px solid #ebeff3;

  ${media.lg`
    display: block;
  `}
`;
