import styled from 'styled-components';
import { media } from '@app/styles';

interface ColProps {
  right?: boolean;
}

export const Col = styled.div<ColProps>`
  text-align: ${props => (props.right ? 'right' : 'left')};
  flex: 1;
  width: 100%;
  margin: 0 1rem;
`;

export const CurrencyCode = styled.span`
  font-weight: 400;
  font-size: 0.8rem;
  margin-right: 4px;
`;

export const SmallText = styled.span`
  font-weight: 400;
  font-size: 0.74rem;
  margin-right: 4px;
  opacity: 0.9;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;

  &:last-child {
    font-weight: 600;
    font-size: 1rem;
  }

  ${Col}:last-child {
    flex: 0;
    flex-basis: 140px;
  }
`;

export const Container = styled.div`
  margin: 1em 0 0;

  h3 {
    font-size: 1rem;
    margin: 1.4em 0;
    font-weight: 100;
    text-align: center;
  }

  ${media.lg`
    margin-top: 0;
  `}
`;

export const Pricing = styled.div`
  margin: 1rem 1rem 0;

  ${Col} {
    margin-bottom: 1rem;
  }

  ${Row} {
    &:first-child {
      margin-top: 0.5rem;
      padding-top: 1rem;
      border-top: 1px solid #eee;
    }

    &:last-child {
      margin-top: 0.5rem;
      padding-top: 1rem;
      border-top: 1px solid #eee;
    }
  }
`;
