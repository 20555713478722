import * as React from 'react';
import { Item } from '@app/lib/graphql/models';
import { formatCurrency } from '@app/lib/utils';
import {
  Container,
  Row,
  Col,
  FrameText,
  RetailPrice,
} from './OrderTableItems.styles';

interface OrderTableItemsProps {
  items: Item[];
  itemsTotal: number;
}

export const OrderTableItems: React.FC<OrderTableItemsProps> = ({ items }) => (
  <Container>
    {items.map(item => {
      return (
        <Row key={item.id}>
          <Col>
            {item.displayName}
            <FrameText>{item.description}</FrameText>
            {!item.isAddOn && (
              <FrameText>
                Size: {item.printMetadata?.width} × {item.printMetadata?.height}{' '}
                inches
              </FrameText>
            )}
            {!item.isDigitalPrint && !item.isRushOrder && (
              <FrameText>Quantity: {item.quantity}</FrameText>
            )}
          </Col>

          <Col right>
            <RetailPrice>
              {!item.isAddOn &&
                formatCurrency(item.retailPrice * item.quantity)}
            </RetailPrice>
            {item.formattedTotal}
          </Col>
        </Row>
      );
    })}
  </Container>
);
