import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo } from '@app/components/Logo';
import { MobileMenu, Navigation, SubHeader, ToolBox } from './components';
import { GlobalBanner } from '@app/components/GlobalBanner';
import { BrowserDetectBanner } from '@app/components/BrowserDetectBanner';
import {
  Container,
  Inner,
  MenuIcon,
  LogoContainer,
  RightMenu,
} from './Header.styles';

interface HeaderProps {
  isAlt?: boolean;
}

export const Header: React.FC<HeaderProps> = props => {
  const router = useRouter();
  const [isMenuActive, setMenuActive] = useState<boolean>(false);

  const isCartPage = router.pathname.indexOf('/cart') > -1;
  const isHomePage = router.pathname === '/';

  React.useEffect(() => {
    if (isMenuActive) {
      document.body.style.overflow = 'hidden';
    }

    if (!isMenuActive) {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuActive]);

  return (
    <>
      <MobileMenu isActive={isMenuActive} setActive={setMenuActive} />

      <Container isAlt={props.isAlt}>
        <GlobalBanner />
        <Inner isAlt={props.isAlt}>
          <MenuIcon
            isActive={isMenuActive}
            onClick={() => setMenuActive(!isMenuActive)}
          >
            {isMenuActive ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
              >
                <path
                  d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"
                  fill="#3f6581"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M492 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zm0-160H20C8.954 76 0 84.954 0 96s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20zm0 320H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20z"
                  fill="#3f6581"
                />
              </svg>
            )}
          </MenuIcon>

          <LogoContainer>
            <Link href="/" passHref>
              <a aria-label="Homepage">
                <Logo />
              </a>
            </Link>
          </LogoContainer>

          <RightMenu>
            <Navigation />
            <ToolBox />
          </RightMenu>
        </Inner>

        {isCartPage && <SubHeader />}
        {(isHomePage || isCartPage) && <BrowserDetectBanner />}
      </Container>
    </>
  );
};
