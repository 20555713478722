import styled from 'styled-components';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ul {
    display: none;
    margin: 0 1em 0 5rem;
    padding: 0;
    list-style: none;
    align-items: center;

    li {
      margin: 0;
      padding: 0;
    }

    a {
      display: block;
      padding: 0.7em 0;
      margin: 0.7em 1em;
      transition: all 200ms ease-in-out;
      font-size: 0.95rem;
      font-weight: 300;
      letter-spacing: 0.03rem;
      text-decoration: none;
      color: ${props => props.theme.textColors['dark-500']};

      &:hover {
        transition: all 200ms ease-in-out;
      }
    }

    ${media.xl`
      display: inline-flex;
    `}
  }

  ${media.lg`
    margin-right: 1rem;
  `}
`;
