import React, { memo } from 'react';
import { useRouter } from 'next/router';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Body } from './ShopLayout.styles';
import config from '@app/config';

const hasGlobalBanner = Boolean(config.globalBanner);

export const ShopLayout: React.FC = memo(({ children }) => {
  const router = useRouter();
  const isCartPage = router.pathname.indexOf('/cart') > -1;

  return (
    <>
      <Header />

      <Body
        isCart={isCartPage}
        hasGlobalBanner={hasGlobalBanner}
        size={hasGlobalBanner ? config.globalBanner.size : null}
      >
        {children}
      </Body>

      <Footer />
    </>
  );
});
