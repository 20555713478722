import React, { useEffect, useState } from 'react';
import { CloseIcon } from '@app/components/InspirationGallery/CloseIcon';
import { Container, Copy, Link } from './BrowserDetectBanner.styles';

export const BrowserDetectBanner: React.FC = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.match(/(MSIE|Trident)/) !== null) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, []);

  if (isActive) {
    return (
      <Container>
        <Copy>
          We noticed you’re using Internet Explorer. Some things may not work
          properly. For the best experience, please use another browser like{' '}
          <Link
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Edge
          </Link>
          ,{' '}
          <Link
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noreferrer"
          >
            Chrome
          </Link>
          , or{' '}
          <Link
            href="https://www.apple.com/safari/"
            target="_blank"
            rel="noreferrer"
          >
            Safari
          </Link>
          .
        </Copy>
        <CloseIcon onClick={() => setIsActive(false)} />
      </Container>
    );
  } else {
    return null;
  }
};

export default BrowserDetectBanner;
