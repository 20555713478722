export default {
  colors: {
    black: '#000',
  },
  body: `
    background: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto','Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: #000000;
    overflow: scroll;
  `,
  a: `
    color: #338d95;
  `,
  bgColors: {
    'light-100': '#fffaf5',
    'light-200': '#f9fdfd',
    'gray-100': '#F5FAFB',
    'light-blue-100': '#EEF8FF',
    'light-blue-200': '#DCF2FF',
  },
  fonts: {
    primary: `
      font-family: 'Montserrat', sans-serif;
    `,
    secondary: `
      font-family: 'DM Serif Display', serif;
    `,
  },
  textColors: {
    'dark-200': '#8090a4',
    'dark-300': '#647c9e',
    'dark-400': '#465c80',
    'dark-500': '#113450',
    'dark-600': '#083533',
    'dark-700': '#000000',
    'light-100': '#b8c2cc',
    'light-200': '#aaaaaa',
    'light-300': '#8795a1',
    'light-400': '#647c9e',
    'light-500': '#113450',
    'light-700': '#3a4f64',
  },
  buttons: {
    common: `
        color: #000;
        display: inline-flex;
        border-radius: 3px;
        text-decoration: none;
        border-style: none;
        border-width: initial;
        border-color: initial;
        border-image: initial;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        letter-spacing: 0.03rem;
    `,
    default: `
      color: #113450;
      background: linear-gradient(to top, #f6f7f9, #ffffff);
      border: 1px solid #efefef;
      font-size: 0.88rem;
      border-radius: 50px;
    `,
    primary: `
      background: #FFBF00;
      font-size: 1.1rem;
      font-weight: 600;
      border-radius: 50px;
    `,
    secondary: `
      background: #2f4760;
      color: white;
      border-radius: 50px;
      width: 100%;
    `,
    text: `
      background: transparent;
      font-size: 0.88rem;
    `,
  },
  alerts: {
    default: `
      background: #f2fbfc;
      border: 1px solid #c6e3e5;
      color: #2b8d96;
    `,
  },
  nav: {
    background: `
      background-color: #EEF8FF;
    `,
  },
};
