import * as React from 'react';
import Link from 'next/link';
import { LinkButton } from '@app/components/ui/Button';
import { Wrapper } from './Navigation.styles';
import { links } from '../../links';

export const Navigation: React.FC = () => (
  <Wrapper>
    <ul>
      {links.map(link => (
        <li key={`nav-list-${link.name}`}>
          <Link href={link.href} passHref prefetch={false}>
            <a>{link.name}</a>
          </Link>
        </li>
      ))}
    </ul>

    <Link href="/create" passHref prefetch={false}>
      <LinkButton
        variant="primary"
        height={38}
        style={{
          fontSize: '0.9rem',
        }}
        className="hidden xl:inline-flex"
      >
        Create Moon Map
      </LinkButton>
    </Link>
  </Wrapper>
);
