import styled from 'styled-components';

interface BodyProps {
  isCart: boolean;
  hasGlobalBanner: boolean;
  size?: string;
}

const marginTop = (
  hasGlobalBanner: boolean,
  isCart: boolean,
  size: string
): number => {
  let margin = 60;

  if (isCart) {
    margin += 40;
  }

  if (size === 'lg') {
    margin += 80;
  } else if (size === 'md') {
    margin += 60;
  } else if (size === 'sm') {
    margin += 40;
  }

  return margin;
};

export const Body = styled.div<BodyProps>`
  margin-top: ${props =>
    marginTop(props.hasGlobalBanner, props.isCart, props.size)}px;
`;
