import { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Container = styled.footer`
  position: relative;
  padding-bottom: 2rem;
  text-align: center;
  background: ${props => props.theme.bgColors['light-200']};
  border-top: 1px solid #e7f3f3;

  > div {
    padding-top: 3em;
  }

  * {
    z-index: 10;
  }

  ${media.lg`
    text-align: left;
    padding-top: 60px;
  `};

  ${media.xxl`
    padding: 0 12rem;
  `}
`;

export const Copyright = styled.div`
  color: #4b607d;
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 1.2em;
`;

export const MadeWithLove = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2.5em 0 1em;
  padding: 0;
  color: #4b607d;
`;

export const MadeWithLoveIcon = styled.span`
  margin: 0 0.7em;
  width: 25px;
  height: 25px;
`;
