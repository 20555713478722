import base from './base';
import redesign from './redesign';

export function getTheme(name: string): unknown {
  let theme: unknown;

  switch (name) {
    case 'redesign':
      theme = redesign;
      break;
    default:
      theme = base;
  }

  return theme;
}
