import styled from 'styled-components';
import { media } from '@app/styles';

export const Menu = styled.ul`
  padding: 0;
  margin: 0.3em 0 1em;
  list-style: none;

  li {
    margin: 0;
    padding: 0;

    a {
      display: inline-block;
      text-decoration: none;
      padding: 0.6em;
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: ${props => props.theme.textColors['dark-500']};
    }
  }
`;

export const Title = styled.h4`
  ${(props: any) => props.theme.fonts.secondary}
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1em;

  ${media.lg`
    margin-bottom: 2em;
  `};
`;
